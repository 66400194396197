import styles from './Information.module.scss'
import image from './assets/image.png'
import { TextInput } from '../../components/Field'
import { Button } from '../../components/Button'
import { useState } from 'react'
import { SuccessPopup } from '../../components/Popup'
import axios from 'axios'

export function Information(props: any) {
    const [successPopup, setSuccessPopup] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            await axios.post('https://tailor-le-ron-backend.herokuapp.com/v1/subscribers/', {
                name: name,
                email: email,
                phone1: phone1,
                phone2: phone2
            });
            setSuccessPopup(true);
        } catch (e) {
            console.log(e)
            alert('Error')
        }
    }

    return (
        <section className={styles.information}>
            <section className={styles.content}>
                <h1>Welcome</h1>
                <p>We will protect all your information you share with us</p>
                <form onSubmit={handleSubmit}>
                    <TextInput label='Full Name' required value={name} onChange={(e) => setName(e.target.value)} />
                    <TextInput label='Email Address' required _props={{ type: 'email' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                    <TextInput label='Phone Number 1' required={false} value={phone1} onChange={(e) => setPhone1(e.target.value)} />
                    <TextInput label='Phone Number 2' required={false} value={phone2} onChange={(e) => setPhone2(e.target.value)} />
                    <Button label='Submit' type='submit' />
                </form>
            </section>
            <section>
                <img src={image} alt='african beauty' />
            </section>
            <SuccessPopup show={successPopup} setShow={setSuccessPopup} />
        </section>
    )
}