import styles from './Field.module.scss'
import { InputProps } from './utils'

export function TextInput(props: InputProps) {
    return (
        <section className={styles.field}>
            <input id={`${props.label}`} required={props.required} onChange={props.onChange} value={props.value} {...props._props} />
            <label htmlFor={`${props.label}`}>{props.label} {!props.required ? '(Optional)' : null}</label>
        </section>
    )
}