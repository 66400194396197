import styles from './Header.module.scss'
import logo from './assets/logo.png'
import { useNavigate } from 'react-router-dom'

export function Header(props: any) {
    const navigate = useNavigate()
    const path = '/'

    const handleNavigate = (e: any) => {
        e.preventDefault();
        navigate(path);
    }
    return (
        <header className={styles.header}>
            <a href={path} onClick={handleNavigate}><img src={logo} alt='logo' /></a>
        </header>
    )
}