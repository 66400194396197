import styles from './Footer.module.scss'
import logo from './assets/logo.png'

export function Footer(props: any) {
    return (
        <footer className={styles.footer}>
            <img src={logo} alt='tailor le ron logo' />
            <h1>Tailor Le Ron™ is simplifying the end to end of bespoke African fashion supply chain with culture hack and technology</h1>
            <section aria-label='social handles' className={styles.socials} >
                <a href='https://instagram.com/jointailorleron' rel="noreferrer" target='_blank'>Follow us on Instagram</a>
                <span>|</span>
                <a href='mailto:hello@tailorleron.com'>hello@tailorleron.com</a>
            </section>
        </footer>
    )
}