import { Link } from '../../components/Button'
import styles from './LearnMore.module.scss'
import image from './assets/image.png'

export function LearnMore(props: any) {
    return (
        <section className={styles.learn_more}>
            <section>
                <article>
                    <h1>we are a culture exchange company first, then a technology company</h1>
                    <p>We are going an extra mile, to accelerate global access to rich African fashion</p>
                    <Link label='Learn More' href='/' />
                </article>
            </section>
            <section>
                <img src={image} alt='a beautiful african girl' />
            </section>
        </section>
    )
}