import styles from './JoinWaitlist.module.scss'
import image from './assets/image.png'
import image2 from './assets/image2.png'
import { Link } from '../../components/Button'
import { EidOrder } from '../EidOrder'

export function JoinWaitlist(props: any) {
    return (
        <section className={styles.join_waitlist}>
            <img src={image} alt='overjoyed african woman' className={styles.header} />
            <section className={styles.body}>
                <article>
                    <p>Be one of the first to try out our users platform when it’s ready.</p>
                    <ul>
                        <li>Personalize your design,</li>
                        <li>Collaborate with a stylist,</li>
                        <li>Pick your choice fabric,</li>
                        <li>We take care of the rest, and Get the full product direct to you.</li>
                    </ul>
                    <Link label='Join the Waitlist' href='/waitlist' />
                </article>
                <section>
                    <img src={image2} alt='photos of black wowen' />
                </section>
            </section>
            <EidOrder />
        </section>
    )
}