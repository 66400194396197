import { ListView } from "../components/List";
import { EidOrder } from "../features/EidOrder";
import { Footer } from "../features/Footer";
import { Header } from "../features/Header";
import { Information } from "../features/Information";

export function Waitlist(props: any) {
    return (
        <ListView header={<Header />}>
            <Information />
            <EidOrder />
            <Footer />
        </ListView>
    )
}