import { Link } from '../../components/Button'
import styles from './EidOrder.module.scss'
import eid from './assets/eid.png'

export function EidOrder(props: any) {
    return (
        <section className={styles.eid_order}>
            <img src={eid} alt='eid cresent' />
            <h1><small>We are taking orders for</small><br />Eid-El-Adha</h1>
            <Link href='https://calendly.com/bakarelawal/tailor-le-ron-inquiry'
                explicit={true}
                label='Book a Session - Zoom'
                _props={{ 'aria-label': 'book a session with us on zoom', target: '_blank' }}
            />
            <p>Available in Washington DC, Maryland and Virginia</p>
        </section>
    )
}