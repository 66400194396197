import { Link } from '../../components/Button'
import styles from './Intro.module.scss'

export function Intro(props: any) {
    return (
        <section className={styles.intro}>
            <article aria-label='what we do'>
                <h1>We stand for proud expression of our culture by innovating the cutting edge of bespoke African fashion</h1>
                <h2 className={styles.small}>Sken-Te-Le</h2>
                <section aria-label='links' className={styles.links}>
                    <Link href='/waitlist'
                        label='Join the Waitlist'
                    />
                    <Link href='https://calendly.com/bakarelawal/tailor-le-ron-inquiry'
                        explicit={true}
                        label='Book a Session - Zoom'
                        _props={{ 'aria-label': 'book a session with us on zoom', target: '_blank' }}
                    />
                </section>
            </article>
        </section>
    )
}