import { useNavigate } from 'react-router-dom'
import styles from './Button.module.scss'
import { ButtonProps, LinkProps } from './utils'

export function Button(props: ButtonProps) {
    return (
        <button className={styles.button} type={props.type} onClick={props.onClick} {...props._props}>
            {props.label}
        </button>
    )
}

export function Link(props: LinkProps) {
    const navigate = useNavigate()

    const handleNavigate = (e: any) => {
        e.preventDefault();
        navigate(props.href);
    }
    return (
        <a href={props.href}
            onClick={props.explicit! !== true ? handleNavigate : undefined}
            className={styles.button}
            {...props._props}>
            {props.label}
        </a>
    )
}