import styles from './Popup.module.scss'
import logo from './assets/logo.png'
import image from './assets/image.png'
import { Link } from '../Button'

export function SuccessPopup(props: { show: boolean, setShow?: Function }) {
    const handleHide = (e: any) => {
        if(props.setShow!==null) props.setShow!(false);
    }
    return (
        <section className={props.show ? styles.popup__show : styles.popup__hide} onClick={handleHide}>
            <section>
                <main className={styles.popup_container}>
                    <header>
                        <img src={logo} alt='tailor le ron logo' />
                    </header>
                    <img src={image} alt='a beautiful african lady' />
                    <h1>You are now one step away from the TLR Magic.</h1>
                    <p>Thank you for joining our waitilist</p>
                    <section aria-label='social handles' className={styles.socials}>
                        <Link href='http://instagram.com/jointailoreleron'
                        explicit={true}
                        label='Follow on Instagram'
                        _props={{ 'aria-label': 'follow our instagram page', target: '_blank' }}
                    />
                        <Link href='https://calendly.com/bakarelawal/tailor-le-ron-inquiry'
                        explicit={true}
                        label='Book a Session'
                        _props={{ 'aria-label': 'book a session with us on zoom', target: '_blank' }}
                    />
                    </section>
                    <p><small>Made with ❤️ in Washington DC</small></p>
                </main>
            </section>
        </section>
    )
}