import { Link } from '../../components/Button'
import styles from './BookASession.module.scss'

export function BookASession(props: any) {
    return (
        <section className={styles.book_a_session}>
            <h1>We serve Africans, and lovers of Africa. We immerse them deeply into a fashion experience</h1>
            <p>Pilot Live in Washington DC, Maryland and Virginia</p>
            <Link href='https://calendly.com/bakarelawal/tailor-le-ron-inquiry'
                explicit={true}
                label='Book a Session - Zoom'
                _props={{ 'aria-label': 'book a session with us on zoom', target: '_blank' }}
            />
        </section>
    )
}