import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Splash } from './features/Splash';
import { Home } from './pages/Home'
import { Waitlist } from './pages/Waitlist';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])

  return (
    <Fragment>
      {loading && <Splash />}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/waitlist' element={<Waitlist />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
