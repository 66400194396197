import React from 'react';
import { ListView } from '../components/List';
import { BookASession } from '../features/BookASession';
import { Footer } from '../features/Footer';
import { Header } from '../features/Header';
import { Intro } from '../features/Intro';
import { JoinWaitlist } from '../features/JoinWaitlist';
import { LearnMore } from '../features/LearnMore';

export function Home() {
  return (
    <ListView header={<Header />}>
      <Intro />
      <LearnMore />
      <BookASession />
      <JoinWaitlist />
      <Footer />
    </ListView>
  );
}
